import { useRouter } from 'next/router';
import { openInNewTab } from '@utils/index';
import { onEnterSpacePress } from '@utils/keyboard';

const NavDropDownPanel = ({ setMenuPanelOpen, navItems }) => {
  const router = useRouter();
  const handleMenuClick = (url, newTab) => {
    setMenuPanelOpen(null);
    if (newTab) {
      openInNewTab(url);
    } else {
      router
        .push(url)
        .then(() => {
          // Resolve sonarqube code bug since Router returns promise.
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  return (
    <div
      className='hidden dropdown-menu top-[72px] float-left h-auto left-0 w-full bg-zinc-50 py-10 px-12 lg:absolute lg:block'
      data-testid='navdropdownpanel'
    >
      <ul className='relative block float-left list-none text-left text-xl leading-7 text-black'>
        {navItems.map(item => (
          <li
            className='hover:text-primary no-underline cursor-pointer select-none'
            key={item.fields?.internalTitle}
          >
            <a
              className='no-underline text-left text-xl leading-7 text-black font-helNowLight font-normal hover:text-primary'
              href={item.fields?.url || '#'}
              onClick={e => {
                e.preventDefault();
                handleMenuClick(
                  item.fields?.url,
                  item.fields?.urlOpenerType === 'New Tab'
                );
              }}
              onKeyDown={e =>
                onEnterSpacePress(() => {
                  e.preventDefault();
                  handleMenuClick(
                    item.fields?.url,
                    item.fields?.urlOpenerType === 'New Tab'
                  );
                })
              }
            >
              {item.fields?.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavDropDownPanel;
