import { useRouter } from 'next/router';
import cx from 'classnames';
import { openInNewTab } from '@utils/index';
import { onEnterSpacePress } from '@utils/keyboard';
import NavDropDownPanel from './navDropdown';

/**
 * @class
 * @param {Boolean} active current active navitem
 * @param {Boolean} topNav Specifies if the Nav element is top navigation
 * @param {Array} navItems Navigation items data
 * @param {Object} rest Additional props
 *
 * @returns Navitems Component as per the nav data
 */

const NavItems = ({
  active,
  navItems,
  topNav = false,
  isFooterNav = false,
  onClick,
  isHeroWithHeading = false,
  setMenuPanelOpen,
  isMenuPanelOpen
}) => {
  const router = useRouter();
  const handleClick = (e, url, newTab) => {
    e.preventDefault();
    onClick?.(e);
    // typeof url is string, thats why 'undefined' is added
    if (url !== '#' && url && url !== 'undefined') {
      if (newTab) {
        openInNewTab(url);
      } else {
        router
          .push(url)
          .then(() => {
            // Resolve sonarqube code bug since Router returns promise.
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };

  const handleMouseEnter = (item, index) => {
    if (item?.navItems?.length > 0) {
      setMenuPanelOpen(index);
    } else if (isMenuPanelOpen >= 0) {
      setMenuPanelOpen(null);
    }
  };

  return navItems?.map(({ fields: item }, index) => {
    const url = `${item.url}`;
    const handleEnter = e => {
      if (e.key === 'Enter') {
        if (
          !topNav &&
          isMenuPanelOpen === index &&
          item?.navItems?.length > 0
        ) {
          setMenuPanelOpen(null);
        } else if (item?.navItems?.length > 0) {
          handleMouseEnter(item, index);
        } else {
          handleClick(e, url, item?.urlOpenerType === 'New Tab');
        }
      }
    };

    return (
      <li key={item?.internalTitle || item?.label} className='list-none'>
        <a
          className={cx(
            'flex',
            'mb-2 mt-2 md:mt-2 lg:mb-0',
            'w-full',
            'sm:w-auto',
            'lg:inline-block',
            'font-medium',
            'lg:mt-0',
            'no-underline',
            'cursor-pointer',
            'select-none',
            'lg:hover:scale-105',
            {
              'md:mr-5 text-[11px] solid border-b-2 font-ProBold text-white uppercase':
                topNav,
              'text-2xl lg:text-xs md:mr-8 font-helNowLight hover:text-primary mt-4 lg:mt-0 mb-4':
                !topNav,
              'lg:text-inherit': active !== url && !topNav && isHeroWithHeading,
              'text-skin-base': active !== url && !topNav && !isHeroWithHeading,
              'text-2xl lg:text-sm': !topNav && !isFooterNav,
              'sm:text-3xl': !isFooterNav & !topNav,
              'lg:text-primary': active === url && !topNav,
              'text-sm': isFooterNav
            }
          )}
          href={item?.url || '#'}
          aria-label={item?.ariaLabel}
          onClick={e => handleClick(e, url, item?.urlOpenerType === 'New Tab')}
          onMouseEnter={() => handleMouseEnter(item, index)}
          onKeyDown={onEnterSpacePress(handleEnter)}
        >
          {item?.icon ? item.icon : null}
          {item?.label}
        </a>
        {!topNav && isMenuPanelOpen === index && item?.navItems?.length > 0 && (
          <NavDropDownPanel
            setMenuPanelOpen={setMenuPanelOpen}
            navItems={item.navItems}
          />
        )}
      </li>
    );
  });
};

export default NavItems;
